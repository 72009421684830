import smoothscroll from 'smoothscroll-polyfill';
import 'promise-polyfill/dist/polyfill.min';
import 'whatwg-fetch';
import 'formdata-polyfill';
import './landing';
import CloseBoxNotification from './box-notifications';

require('formdata-polyfill');

// Polyfill for native smooth scrolling
smoothscroll.polyfill();

// eslint-disable-next-line
const closeBoxNotification = new CloseBoxNotification({
  element: '.box-notification--error'
});

// NodeList.prototype.forEach polyfill
if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = function (callback, thisArg) {
    thisArg = thisArg || window;
    for (var i = 0; i < this.length; i++) {
      callback.call(thisArg, this[i], i, this);
    }
  };
}
