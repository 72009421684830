import Vue from 'vue';
import misc from './misc';
import SubscriptionsTua from './vue/components/Subscriptions-TUA.vue';
/*
  Usage:

  Add your link:
  <a data-lightbox-id="[yourlightbox-id]" class="lightbox-trigger" href="#">Button here</a>

  Create your lightbox:
  <div id="[yourlightbox-id]" class="lightbox-wrapper" style="display:none">
    <div class="lightbox-content">
    <div class="lightbox-inner">
      [YOUR CONTENT HERE]
    </div>
    </div>
    <div class="lightbox-canvas"></div>
  </div>

  Lightbox are stored here:
  parts/lightbox.php

*/

class ModalLightbox {

  constructor(options) {

    // List of handlers
    this.onOpen = Object.prototype.hasOwnProperty.call(options, 'onOpen') ? options.onOpen : false;
    this.enableOutsideClick = options.hasOwnProperty('enableOutsideClick') ? options.enableOutsideClick : true;
    this.onClick = this.onClick.bind(this);
    this.closeLightboxByButton = this.closeLightboxByButton.bind(this);
    this.enableEscKey = this.enableEscKey.bind(this);
    
    if (this.enableOutsideClick) {
      this.handleOutsideClick = this.handleOutsideClick.bind(this);
    }

    // List of <a data-lightbox-id="[yourlightbox-id"]></a>
    this.triggerList = Array.prototype.slice.call(document.querySelectorAll(options.trigger));
    this.triggerList.forEach(item => {
      item.addEventListener('click', this.onClick);
    });

  }

  onClick(event) {
    event.preventDefault();
    // Get the lightbox ID from <a data-lightbox-id=""></a>
    const lightboxWrapper = document.querySelector(`#${event.currentTarget.dataset.lightboxId}`);
    // If lightbox id doesn't exist
    if (lightboxWrapper === null) return console.error(`Lightbox: "${event.currentTarget.dataset.lightboxId}" not found`);
    return this.openLightbox(lightboxWrapper);
  }

  openLightbox(lightboxWrapper) {
    lightboxWrapper.style.display = '';

    document.body.style.overflow = 'hidden';
    lightboxWrapper.classList.add('on-transition');

    const afterAnimation = () => {
      lightboxWrapper.classList.remove('on-transition');
      lightboxWrapper.classList.add('active');

      // Listen to certain events after is open
      document.addEventListener('keyup', this.enableEscKey);
      if (this.enableOutsideClick) { // We may not wish this to happen on certain important lightbox (Eg: Login)
        document.addEventListener('click', this.handleOutsideClick);
      }

      lightboxWrapper.querySelector('.op-close')
        .addEventListener('click', this.closeLightboxByButton);

      // Optional Callback
      typeof this.onOpen === 'function' && this.onOpen();

      // This action should be always at the end
      lightboxWrapper
        .removeEventListener(misc.getAnimations(), afterAnimation);
    };

    misc.getAnimations() && lightboxWrapper
      .addEventListener(misc.getAnimations(), afterAnimation);

  }

  enableEscKey(event) {
    if (event.keyCode === 27) {
      this.closeLightbox();
    }
  }

  handleOutsideClick(event) {
    const lightboxWrapper = document.querySelector('.lightbox-wrapper.active .lightbox-inner');
    misc.hasClickedOutside(lightboxWrapper, event.target).then(() => {
      this.closeLightbox();
    });
  }

  closeLightboxByButton(event) {
    event.preventDefault();
    this.closeLightbox();
  }

  closeLightbox() {
    const lightboxWrapper = document.querySelector('.lightbox-wrapper.active');

    document.removeEventListener('click', this.handleOutsideClick);
    // ['click','touchstart'].forEach( item => {
    //     document.removeEventListener(item, this.handleOutsideClick );
    // });

    lightboxWrapper.querySelector('.op-close')
      .removeEventListener('click', this.closeLightboxByButton);

    lightboxWrapper.style.animationDirection = 'reverse';
    lightboxWrapper.classList.add('on-transition');
    lightboxWrapper.classList.remove('active');

    const afterAnimation = () => {
      lightboxWrapper.classList.remove('on-transition');
      lightboxWrapper.style.animationDirection = '';
      lightboxWrapper.style.display = 'none';
      document.body.style.overflow = '';

      document.removeEventListener('keyup', this.enableEscKey);
      document.removeEventListener('click', this.handleOutsideClick);

      lightboxWrapper
        .removeEventListener(misc.getAnimations(), afterAnimation);
    };

    misc.getAnimations() && lightboxWrapper
      .addEventListener(misc.getAnimations(), afterAnimation);

  }

}

const VolarisLightbox = new ModalLightbox({ // eslint-disable-line no-unused-vars
  trigger: '.lightbox-trigger'
});

const VolarisLightboxLogin = new ModalLightbox({ // eslint-disable-line no-unused-vars
  trigger: '.lightbox-login-trigger',
  enableOutsideClick: false,
  onOpen: () => {
    document.querySelector('input[name="email"]').focus();
  }
});

const VolarisLightboxTUA = new ModalLightbox({ // eslint-disable-line no-unused-vars
  trigger: '.lightbox-trigger-tua',
  onOpen: () => {
    const container = document.querySelector('#form-subscriptions-tua');
    if (container.classList.contains('loaded')) {
      // Vue component already initialized
      return false;
    }
    // Inizialize and mount Vue component
    const target = document.querySelector('subscriptions-tua');
    const TuaConstructor = Vue.extend(SubscriptionsTua);
    const tua = new TuaConstructor({
      propsData: {
        formTitle: target.dataset.formTitle,
        formLoaderGif: target.dataset.formLoaderGif,
        formSelectPlaceholder: target.dataset.formSelectPlaceholder,
        formResultTitle: target.dataset.formResultTitle,
        formResultContent: target.dataset.formResultContent,
        selectedCurrency: target.dataset.selectedCurrency,
      }
    });
    tua.$mount('subscriptions-tua');
  }
});

// For testing purposes
document.addEventListener('DOMContentLoaded', () => {
  // document.querySelector('a[data-lightbox-id="lightbox-searchflights-details-1564579020000"]').click();
});
