<template>
  <div class="content-form">
    
    <transition name="fade" mode="out-in">

      <div v-if="!isComponentLoaded" class="volaris-loader pt2" :key="'loader'">
        <img v-bind:src="formLoaderGif" v-bind:alt="formTitle">
      </div>
      
      <div v-if="isComponentLoaded" class="pt2" :key="'component'">
        <h5>{{ formTitle }}</h5>
        <div class="field pt1 ">
          <select class="select" name="airport-taxes-departure" id="airport-taxes-departure" v-model="selectedAirport">
            <option value="0" selected disabled>{{ formSelectPlaceholder }}</option>
            <option v-for="(item, index) in list" v-bind:value="index"> {{ item['airport'] }} </option>
          </select>
        </div>
          <transition name="fade">
            
            <div v-if="selectedAirport" :key="'wrapper'">

              <div class="field-bordered-top field-tax-info bordered-full">
                <p class="pt1">{{ formResultTitle }}:</p>  
                <transition name="fade" mode="out-in">
                  <div v-if="selectedAirport" :key="selectedAirport" class="pt1 flex-cell">
                    <div class="label"><h4>{{ list[selectedAirport]['airport'] }}</h4></div>
                    <div class="value"><h4> ${{ list[selectedAirport][taxPriceIndex] }} {{selectedCurrency.toUpperCase()}} </h4></div>
                  </div>
                </transition>
              </div>
            
            </div>
          </transition>
        <p class="pt2 lightgray fsz12">{{ formResultContent }}: {{ lastUpdated }}</p>
      </div>

    </transition>

  </div>
</template>
<script>
  export default {
    props: [
      'selectedCurrency', 'formTitle', 'formLoaderGif', 'formSelectPlaceholder', 'formResultTitle', 'formResultContent'
    ],
    data(){
      return {
        list: [],
        lastUpdated: '',
        selectedAirport: 0,
        taxPriceIndex: '',
        currency: '',
        isComponentLoaded: false
      }
    },
    mounted() {
      fetch('/y4/subscriptions/tua', {
        method : "GET",
      }).then( response => response.json() ).then( data => {
        // FIXME: this requires knowledge of container
        document.querySelector('#form-subscriptions-tua').classList.add('loaded');
        this.taxPriceIndex = `domestic_${this.selectedCurrency}`;
        this.list = data.list;
        this.lastUpdated = data.lastUpdated;
        this.isComponentLoaded = true;
      })
    }
  }
</script>
