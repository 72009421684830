import misc from './misc';

const dropdownBox = function(options){

    this.wrapper = document.querySelector(options.wrapper);
    this.trigger = document.querySelector(options.trigger);

    this.init = () => {
        if(this.trigger === null){
            return false;
        }

        if(this.wrapper === null){
            console.error(`Error on dropdownBox: ${options.wrapper} doesn't exist.`)
        }

        this.trigger.addEventListener('click', this.onClick )
        window.addEventListener('resize', this.onResize )
    }

    this.onClick = event => {
        event.preventDefault();
        if( !this.trigger.classList.contains('active') ){
            return this.openDropdown(event);
        } else {
            return this.closeDropdown()
        }
    }

    this.openDropdown = event => {
        
        this.wrapper.style.display = ''

        this.handlePositions()

        this.trigger.classList.add('active')
        this.wrapper.classList.add('on-transition')
        
        const afterTransition = () => {
            this.wrapper.classList.remove('on-transition')
            const events = ['click','touchstart']
            events.forEach( item => {
                document.addEventListener(item, this.handleOutsideClick );
            })

            // This action should be always at the end
            this.wrapper
                .removeEventListener(misc.getAnimations(), afterTransition )
        }

        misc.getAnimations() && this.wrapper
            .addEventListener(misc.getAnimations(), afterTransition )
    } 

    this.closeDropdown = () => {
        
        this.trigger.classList.remove('active')

        this.wrapper.style.animationDirection = 'reverse'
        this.wrapper.classList.add('on-transition')

        let afterCloseTransition = () => {
            this.wrapper.style.display = 'none'
            this.wrapper.classList.remove('on-transition')
            this.wrapper.style.animationDirection = ''

            const events = ['click','touchstart']
            events.forEach( item => {
                document.removeEventListener(item, this.handleOutsideClick );
            })

            this.wrapper
                .removeEventListener(misc.getAnimations(), afterCloseTransition )
        }

        misc.getAnimations() && this.wrapper
            .addEventListener(misc.getAnimations(), afterCloseTransition )
    }

    this.onResize = () => {

        if ( misc.getViewport().width > 960 && this.trigger.classList.contains('active') ){
            this.closeDropdown()
        }

        if ( misc.getViewport().width < 960 && this.trigger.classList.contains('active') ){
            this.handlePositions()
        }

    }

    this.handlePositions = () => {

        // var posY = this.trigger.getBoundingClientRect().y + this.trigger.getBoundingClientRect().height + this.trigger.getBoundingClientRect().top
        // var posX = (this.trigger.getBoundingClientRect().x - this.wrapper.getBoundingClientRect().width) + (this.trigger.getBoundingClientRect().width/2 + 32);
        let posY = this.trigger.getBoundingClientRect().top + this.trigger.getBoundingClientRect().height + 12
        let posX = (this.trigger.getBoundingClientRect().left - this.wrapper.getBoundingClientRect().width) + (this.trigger.getBoundingClientRect().width/2 + 32);
        
        this.wrapper.style.left = `${posX}px`;
        this.wrapper.style.top = `${posY}px`;

        
    }

    this.handleOutsideClick = event => {
        misc.hasClickedOutside(this.wrapper, event.target).then( () => {
            if( !misc.isLightboxOpen() ){
                this.closeDropdown()
            }
        })
    }

}

const mobileMenu = new dropdownBox({
    wrapper: '#header-mobile',
    trigger: '.header-wrapper .top-menu-nav-trigger a'
});
mobileMenu.init();

const toolsMenu = new dropdownBox({
    wrapper: '#header-tools',
    trigger: '.header-wrapper .top-menu-language a'
});
toolsMenu.init();

const toolsLoggedInMenu = new dropdownBox({
    wrapper: '#header-logged-in-tools',
    trigger: '.header-wrapper .top-menu-logged-in a'
});
toolsLoggedInMenu.init();

// document.querySelector('.header-wrapper .top-menu-language a').click()