class SmoothScroll {
  constructor() {
    const list = document.querySelectorAll('a[href*="#"]');

    list.forEach(item => {
      item.addEventListener('click', event => {
        if (!event.currentTarget.hash.length) {
          return false;
        }

        event.preventDefault();

        const divTarget = document.querySelector(`#${event.currentTarget.href.split('#')[1]}`);

        window.scroll({
          top: divTarget.offsetTop - document.querySelector('.header-wrapper').getBoundingClientRect().height,
          left: 0,
          behavior: 'smooth'
        });
      });
    });
  }
}

// eslint-disable-next-line
const volarisIndexScroll = new SmoothScroll();
