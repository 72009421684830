/**
 * Global notification box helper
 *
 * Required HTML Example
 * ---------------------
 * <div class="box-notification box-notification--error mt1">
 *   <a href="#" class="op-close">{!! HTML::getSVG('op-close.svg') !!}</a>
 *   <div class="entry-content">[Your Content]</div>
 * </div>
 *
 * See CSS @ resources/assets/volaris/subscriptions/stylus/components/notifications.styl
 * See PHP Example @ resources/views/desktop/subscriptions/volaris/plans.blade.php
 */
export default class CloseBoxNotification {
  /*
    options
      element: className selector
      onfinish: Callback function
  */
  constructor(options) {
    this.options = options;
    this.close = this.close.bind(this);
    this.element = document.querySelectorAll(options.element);

    if (!this.element) {
      return false;
    }

    this.element.forEach(item => {
      const closeTrigger = item.querySelector('.op-close');

      if (!closeTrigger) {
        return false;
      }

      closeTrigger.addEventListener('click', this.close);
    });
  }

  close(event) {
    event.preventDefault();
    const currentElement = event.currentTarget;
    const notificationBox = currentElement.parentNode;
    const itemHeight = parseInt(notificationBox.getBoundingClientRect().height);
    const animation = notificationBox.animate({
      height: [`${itemHeight}px`, 0],
      opacity: [1, 0],
      transform: ['translateY(0)', 'translateY(-0.5rem)']
    }, {
      duration: 300,
      fill: 'forwards'
    });

    animation.onfinish = () => {
      currentElement.removeEventListener('click', this.close);
      if (typeof this.options.onfinish === 'function') {
        this.options.onfinish(this.closeAnimation, notificationBox);
      }

      notificationBox.style.height = '0';
      notificationBox.style.display = 'none';
      animation.cancel();
    };
  }
}
