import Misc from './misc';
import { fromUnixTime, intervalToDuration, isAfter, isBefore } from 'date-fns';

// Clouds moving on landing page
class LandingClouds { 
  constructor() {
    this.cloudsWrapper = document.querySelector('.flex-index-hero-wrapper .bg-bottom-clouds');
    if(!this.cloudsWrapper) {
      return false;
    }
    this.onScroll = this.onScroll.bind(this);
    
    window.addEventListener('scroll', this.onScroll);
    document.addEventListener('DOMContentLoaded', () => {
      this.onScroll();
      this.cloudsWrapper.classList.remove('unloaded');
    });

  }
  onScroll(event) {
    if (Misc.getViewport().width < 960) {
      this.cloudsWrapper.style.backgroundPosition = `50% 0`;
      return false;
    }

    const scrollY = window.pageYOffset || document.documentElement.scrollTop;
    const base = 50;
    const cloudPosX = parseInt(scrollY/5) + base;
    this.cloudsWrapper.style.backgroundPosition = `${cloudPosX}% 0`;
  }
}

class Countdown {
  displayStart;
  displayEnd;
  interval;
  now;
  
  constructor() {
    this.countdownStart = fromUnixTime(window.CVO.countdownStartTimestamp);
    this.countdownEnd = fromUnixTime(window.CVO.countdownEndTimestamp);
    this.now = new Date();
    this.interval = setInterval(this.count.bind(this), 1000);
    this.setCountdownActive();
  }

  count() {
    if (this.isExpired || this.isBefore) {
      clearInterval(this.interval);
    } else {
      this.now = new Date();
      this.updateUI();
    }
  }

  updateUI() {
    const hoursEl = document.getElementById('hours-unit');
    const minutesEl = document.getElementById('minutes-unit');
    const secondsEl = document.getElementById('seconds-unit');
    hoursEl.innerText = this.countdown.hours;
    minutesEl.innerText = this.countdown.minutes;
    secondsEl.innerText = this.countdown.seconds;
  }

  setCountdownActive() {
    this.updateUI();
    const countdownEl = document.getElementById('countdown');
    countdownEl.classList.remove('is-stopped');
  }

  get countdown() {
    const duration = intervalToDuration({
      start: this.now,
      end: this.countdownEnd
    });
    const addZeros = duration => duration < 10 ? `0${duration}` : duration;
    return {
      hours: addZeros(duration.hours + duration.days * 24),
      minutes: addZeros(duration.minutes),
      seconds: addZeros(duration.seconds),
    }
  }

  get isBefore() {
    return isBefore(this.now, this.countdownStart);
  }

  get isExpired() {
    return isAfter(this.now, this.countdownEnd);
  }
}

new LandingClouds();

if(window.CVO && window.CVO.countdownStartTimestamp) {
  new Countdown();
}