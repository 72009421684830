import Misc from './misc';
/*
    The following DOM structure is required in order to get MiscAccordion to work

    div.accordion-item
        div.accordion-item-header
        div.accordion-item-content
          div.accordion-item-inner
*/
class MiscAccordion {

  constructor(options) {
    this.closeOthers = options.closeOthers || false;
    this.toggle = options.toggle || false;
    this.accordionItemActiveClass = options.itemActiveClass || false;

    // The full Accordion wrapper
    this.accordionWrapper = options.itemWrapper;
    // The Accordion's content
    this.itemContent = options.itemContent;
    // The list of element under the Accordion
    this.accordionList = Array.prototype.slice.call(document.querySelectorAll(options.item));

    this.openQuestion = this.openQuestion.bind(this);
    this.closeQuestion = this.closeQuestion.bind(this);

    if (!this.accordionList.length) {
      return false;
    }

    const onClick = event => {
      const currentTarget = event.currentTarget;
      if (!currentTarget.classList.contains('accordion-item-active')) {
        this.openQuestion(currentTarget);
      }
    };

    this.accordionList.forEach(item => {
      item.addEventListener('click', onClick, true);
    });

  }

  openQuestion(accordionItem) {
    this.closeOthers && this.resetActive();
    const accordionContentWrapper = accordionItem.querySelector(this.itemContent);

    accordionContentWrapper.style.display = '';
    accordionContentWrapper.style.height = '0px';

    const itemAnswerHeight = accordionItem
      .querySelector('.accordion-item-content .accordion-item-content-inner')
      .getBoundingClientRect().height;

    accordionContentWrapper.style.height = `${itemAnswerHeight}px`;

    accordionItem.classList.add('accordion-item-active');
    this.accordionItemActiveClass && accordionItem.classList.add(this.accordionItemActiveClass);

    const afterTransition = () => {

      accordionContentWrapper.style.height = '';
      this.toggle && accordionItem.querySelector('.accordion-item-header')
        .addEventListener('click', this.closeQuestion, true);

      accordionContentWrapper
        .removeEventListener(Misc.getTransitions(), afterTransition);
    };

    Misc.getTransitions() && accordionContentWrapper
      .addEventListener(Misc.getTransitions(), afterTransition);

  };

  closeQuestion(event) {
    var accordionItem = event.currentTarget.parentElement;
    return this.closeActive(accordionItem);
  }

  closeActive(accordionItem) {
    accordionItem.classList.remove('accordion-item-active');
    this.accordionItemActiveClass && accordionItem.classList.remove(this.accordionItemActiveClass);

    const itemAnswerHeight = accordionItem
      .querySelector('.accordion-item-content .accordion-item-content-inner')
      .getBoundingClientRect().height;

    accordionItem.querySelector('.accordion-item-content').style.height = `${itemAnswerHeight}px`;

    setTimeout(() => {

      accordionItem.querySelector('.accordion-item-content').style.height = '0px';
      // These further actions need to run after transition is done, so we will keep it in a file
      const afterTransition = () => {
        accordionItem.querySelector('.accordion-item-content').style.height = '';
        accordionItem.querySelector('.accordion-item-content').style.display = 'none';
        accordionItem.querySelector('.accordion-item-header').removeEventListener('click', this.closeQuestion, true);

        // We finish, so we remove the event
        accordionItem.querySelector('.accordion-item-content')
          .removeEventListener(Misc.getTransitions(), afterTransition);
      };

      // Once the transition is done we will run afterTransition
      Misc.getTransitions() && accordionItem
        .querySelector('.accordion-item-content')
        .addEventListener(Misc.getTransitions(), afterTransition);

    }, 10);

  }

  resetActive() {
    const accordionItemActive = document.querySelector(`${this.accordionWrapper} .accordion-item.accordion-item-active`);

    if (accordionItemActive === null) {
      return;
    }

    this.closeActive(accordionItemActive);
  }

}

const indexFaq = new MiscAccordion({
  itemWrapper: '#index-faq',
  item: '#index-faq .faq-item',
  itemContent: '#index-faq .faq-item .faq-item-answer',
  itemActiveClass: 'faq-item-active',
  closeOthers: true,
  toggle: true
});

const pageFaq = new MiscAccordion({
  itemWrapper: '#page-faq .faq-list',
  item: '#page-faq .faq-list .faq-item',
  itemActiveClass: 'faq-item-active',
  itemContent: '.faq-item-answer',
  closeOthers: false,
  toggle: true
});

const pageFaqGroup = new MiscAccordion({
  itemWrapper: '#page-faq',
  item: '#page-faq .faq-group',
  itemContent: '.faq-list',
  closeOthers: false,
  toggle: true
});
