import misc from './misc';
const Swiper = require('./vendor/swiper.min');

class indexRating {
	constructor() {
		if (!document.querySelector('.rating-swiper-container')) {
			return false;
		}
		this.handleSwiper = this.handleSwiper.bind(this)
		if( misc.getViewport().width < 640 ){
			this.initSwiper()
		}
		window.addEventListener('resize', this.handleSwiper )
	}
	
	initSwiper() {
		if( !document.querySelector('.rating-swiper-container').classList.contains('active') ){
			this.mySwiper = new Swiper ('.rating-swiper-container', {
				containerModifierClass: 'rating-swiper-container',
				wrapperClass: 'rating-swiper-wrapper',
				slidesPerView: 1,
				spaceBetween: 0,
				watchSlidesVisibility: true,
				// autoHeight: true,
				loop: true,
				slideClass: 'item',
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev',
				},
				on: {
					init(){
						document.querySelector('.rating-swiper-container').classList.add('active');
					}
				}
			})
		}
	}

	handleSwiper() {
		if (this.mySwiper !== undefined ) {
			if(misc.getViewport().width > 640 ){
				document.querySelector('.rating-swiper-container').classList.remove('active')
				!this.mySwiper.destroyed && this.mySwiper.destroy()
			} else {
				this.initSwiper()
			}
		}
		if (this.mySwiper === undefined && misc.getViewport().width < 640 ) {
			this.initSwiper()   
		}
	}
}

const volarisIndexRating = new indexRating()